import React from "react";
import { useTranslation } from "react-i18next";

import { PortalCard } from "./PortalCard";

import "./index.css";
import WindowsSvgIcon from "@components/common/SvgIcons/WindowsSvgIcon";
import AndroidSvgIcon from "@components/common/SvgIcons/AndroidSvgIcon";

export default function Home() {
    const { t } = useTranslation();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    const [isLoading, setIsLoading] = React.useState(true);

    const cardsData: any = {
        firsContainer: [
            {
                title: "Kiosk application",
                secondTitle: "Simplify order taking at the point of sale",
                img: "kiosk.png",
                description:
                    "The Kiosk application is an intuitive order-taking solution that streamlines the process for customers and enhances service efficiency in restaurants.",
                link: "",
                allDescriptions: [
                    "Personalized customer journey",
                    "Custom GUI",
                    "Tailor-made article workflow",
                    "Upselling",
                    "Loyalty module",
                    "PMR/Child mode Multilingual solution",
                    "Automatic translation module",
                    "Multiple payment methods",
                    "Allergen management",
                    "Nutrition management",
                ],
                windowsDownloadLink: "aures-kiosk.exe",
            },
            {
                title: "Production screen",
                secondTitle: "Production improvement tool",
                img: "productionImg.png",
                description:
                    "The kitchen screen (KDS or Kitchen Display Screen) replaces and/or complements the order printing system and allows:",
                allDescriptions: [
                    "Optimize production times",
                    "Display order preparation times",
                    "Enhance product preparation process",
                    "Provide real-time order status",
                    "Manage printing of adhesive labels",
                    "Issue alerts to ensure hygiene compliance",
                    "Apply filters based on order source",
                    "Display orders in portrait or landscape mode",
                ],
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "Production control screen",
                secondTitle: "Display order preparation statuses",
                img: "controller.png",
                description:
                    "The KDS (Kitchen Display Screen) production control screen replaces and/or complements the order printing system",
                allDescriptions: [
                    "Provide real-time order status",
                    "Check the precise content of each order",
                    "Notify the customer as soon as the order is ready (via beepers, notifications, SMS)",
                    "Manage orders with various sales modes and lead time options",
                    "Apply filters based on order source",
                    "Display orders in portrait or landscape mode",
                    "Enhance overall customer experience",
                ],
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "Queue management screen",
                secondTitle: "Display ready orders",
                img: "ORB.png",
                description:
                    "The queue management screen displays order numbers to notify customers to come to the counter and pick up their items.",
                allDescriptions: [
                    "Display order numbers for ready orders",
                    "Sound alert when order is ready",
                    "Call out order number vocally",
                    "Offer advertising animations for waiting customers",
                ],
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "ETK Click & Collect",
                secondTitle: "Order on smartphone",
                img: "etkc&c.png",
                description:
                    "The C&C app provides a modern digital experience, improving customer satisfaction and increasing average order size.",
                allDescriptions: [
                    "Parenteral Interface",
                    "Add filters based on order source",
                    "Display preparation times",
                    "Display Alerts/Warnings",
                ],
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "ETK SCO",
                secondTitle: "Self-Service application",
                img: "SCO.png",
                description: "Instant connection with ERP:",
                allDescriptions: [
                    "Quick identification of scanned items",
                    "Item search functionality",
                    "Multilingual service for autonomy",
                    "Personalized offer management",
                    "Association with loyalty programs and offers.",
                ],
                link: "",
                windowsDownloadLink: "",
            },
        ],
    };

    const fetchListUidEntityBeta = async () => {
        try {
            const url = `${
                process.env.REACT_APP_UUID_BETA
            }?cacheBuster=${Date.now()}`;
            const response = await fetch(`${url}`);
            if (!response.ok) {
                throw new Error(
                    `Erreur lors du chargement des données: ${response.status} ${response.statusText}`
                );
            }
            const data = await response.json();

            return data;
        } catch (error) {
            // eslint-disable-next-line
            console.error(
                "Erreur lors du chargement de listUidEntity :",
                error
            );
            return [];
        }
    };

    React.useEffect(() => {
        if (
            uidUserEntity &&
            process.env.REACT_APP_BETA !== process.env.REACT_APP_PROD
        ) {
            fetchListUidEntityBeta().then((UidEntityBeta) => {
                const isUserBeta = UidEntityBeta.some(
                    (item: any) => item.uuidEntity === uidUserEntity
                );

                const redirectTo = isUserBeta
                    ? process.env.REACT_APP_BETA
                    : process.env.REACT_APP_PROD;

                if (redirectTo && redirectTo !== baseUrl) {
                    localStorage.clear();
                    const newUrl = `${redirectTo}`;
                    try {
                        window.location.replace(newUrl);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.error("Erreur lors de la redirection :", error);
                    }
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [uidUserEntity]);

    return isLoading ? null : (
        <React.Fragment>
            <div
                style={{ backgroundColor: "rgb(0, 0, 0)" }}
                className="installer-main-outer_clz"
            >
                <div className="installer-main-content__clz pt-4">
                    <div className="installer-main-title__clz">
                        {t("DISCOVER THE ETK 360 SUITE")}
                    </div>
                    <div className="installer-p-main-title__clz">
                        {t(
                            "All applications and resources at your fingertips at every stage of your project. Bring your project to life, wherever you are."
                        )}
                    </div>
                    <a
                        href="aures-middleware.EXE"
                        download
                        style={{ textDecoration: "none" }}
                    >
                        <div className="installer-download__clz pt-3 pl-3">
                            <WindowsSvgIcon />
                            <div className="installer-download-title__clz">
                                {t("Download on Windows")}
                            </div>
                        </div>
                    </a>
                    <div
                        className="installer-download-android__clz pt-3 pl-3"
                        style={{ opacity: "0.6" }}
                    >
                        <div>
                            <AndroidSvgIcon />
                        </div>
                        <div className="installer-download-title__clz">
                            {t("Coming soon on Android")}
                        </div>
                    </div>
                </div>
                <div className="installer-main-image__clz">
                    <img src="etk-main.png" className="img" alt="" />
                </div>
            </div>
            <div className="installer-global-title__clz">
                <h1
                    style={{ color: "rgb(0, 0, 0)" }}
                    className="installer-title__clz"
                >
                    {t("THE ETK 360 SUITE")}
                </h1>
            </div>
            <div className="installer-cards">
                <div className="installer-container__clz">
                    {cardsData.firsContainer.map((el: any) => (
                        <PortalCard
                            secondTitle={el.secondTitle}
                            allDescriptions={el.allDescriptions}
                            windowsDownloadLink={el.windowsDownloadLink}
                            title={el.title}
                            description={el.description}
                            link={el.link}
                            img={el.img}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
