import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useNavigate } from "react-router-dom";

import NProgressWrapper from "@components/common/NProgressWrapper";
import PageTitle from "@components/common/PageTitle";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { BlocArchiveAccountLogin } from "./BlocArchiveAccountLogin";

import ShowPasswordSvgIcon from "@components/common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/common/SvgIcons/HidePasswordSvgIcon";
import PortalLogoSvgIcon from "@components/common/SvgIcons/PortalLogoSvgIcon";

import "./Login.css";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isInvalidCode, setIsInvalidCode] = React.useState<boolean>(false);
    const [generalMessageError, setGeneralMessageError] =
        React.useState<any>("");
    const [isUnverifiedCode, setIsUnverifiedCode] =
        React.useState<boolean>(false);
    const [isEnterInput, setIsEnterInput] = React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);

    const [messageError, setMessageError] = React.useState<any>(
        t("This field is required")
    );
    const [messagePwdError, setMessagePwdError] = React.useState<any>(
        t("This field is required")
    );

    const [inputEmail, setInputEmail] = React.useState<string>("");
    const [inputPwd, setInputPwd] = React.useState<string>("");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [email, setEmail] = React.useState<string>("");

    const [isActiveUserModalOpned, setIsActiveUserModalOpned] =
        React.useState<boolean>(false);
    const [msgTitleModal, setMsgTitleModal] = React.useState<string>("");
    const [isForgotPasswordClick, setIsForgotPasswordClick] =
        React.useState<boolean>(false);

    async function handleValidSubmit(email: any, passWord: any) {
        if (!isForgotPasswordClick) {
            const urlVerifyUserAccount: string = `${process.env.REACT_APP_ADMIN_ACCESS}/customerAccount`;

            await fetch(urlVerifyUserAccount, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `email=${email.toLowerCase()}&password=${passWord}`,
                method: "POST",
            })
                .then((result) => result.json())
                .then((response: any) => {
                    if (!response.error) {
                        //vérif status account
                        if (response.data.etataccount === "Activer") {
                            localStorage.setItem(
                                "Uid_Entitie_User",
                                response.data.uidentitie
                            );
                            localStorage.setItem(
                                "firstName",
                                response.data.firstname
                            );
                            localStorage.setItem(
                                "lastName",
                                response.data.name
                            );
                            localStorage.setItem(
                                "uuidprofile",
                                response.data.uuidprofile
                            );
                            localStorage.setItem("email", response.data.email);
                            localStorage.setItem(
                                "secondaryColor",
                                response.data.secondarycolor
                            );
                            localStorage.setItem(
                                "mainColor",
                                response.data.maincolor
                            );
                            setLoading(false);
                            navigate("/Gestioncomptes/Comptesclients");
                        } else {
                            setIsActiveUserModalOpned(true);

                            if (response.data.accounttype === "ADMIN") {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter notre service support."
                                );
                            } else {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter votre administrateur."
                                );
                            }
                        }
                    } else {
                        setGeneralMessageError(
                            t(
                                "Please verify your email address and/or password."
                            )
                        );
                    }
                    setLoading(false);
                })
                .catch((err: any) => {
                    setGeneralMessageError(
                        t("Please verify your email address and/or password.")
                    );
                    setLoading(true);
                    return false;
                });
        }
    }

    function toggleOpeningForgotPasswordModal() {
        setIsModalOpened(!isModalOpened);
    }

    function handleCreateAccountButtonOnClickEvent() {
        navigate("registration");
    }

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null) {
            navigate("/Gestioncomptes/Comptesclients");
        }
    });

    return (
        <React.Fragment>
            <PageTitle title={t("Login")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />

                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden login-card w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Login")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e: any, v: any) => {
                                        setEmail(v.email);
                                        if (
                                            v.password === "" ||
                                            v.email === ""
                                        ) {
                                            if (v.password === "") {
                                                setMessagePwdError(
                                                    t("This field is required")
                                                );
                                            }
                                            if (v.email === "") {
                                                setMessageError(
                                                    t("This field is required")
                                                );
                                            }
                                        } else {
                                            handleValidSubmit(
                                                v.email,
                                                v.password
                                            );
                                        }
                                        setIsEnterInput(false);
                                    }}
                                    onKeyDown={(e: any) => {
                                        if (e.key === "Enter")
                                            e.preventDefault();
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <StyledLabel htmlFor="email">
                                            {t("Username")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="form-control__clz"
                                            placeholder={t("Username")}
                                            onChange={(e: any) => {
                                                setInputEmail(e.target.value);
                                                if (
                                                    generalMessageError !== ""
                                                ) {
                                                    setGeneralMessageError("");
                                                    setIsEnterInput(true);
                                                }
                                            }}
                                            value={inputEmail}
                                            validate={{
                                                email: {
                                                    value: true,
                                                    errorMessage: messageError,
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: messageError,
                                                },
                                            }}
                                            onBlur={(inputText: any) => {
                                                const forbiddenCharactersRegex =
                                                    new RegExp(
                                                        /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/
                                                    );

                                                if (
                                                    !inputText.target.value.match(
                                                        forbiddenCharactersRegex
                                                    )
                                                ) {
                                                    setMessageError(
                                                        t(
                                                            "Please enter a valid username in the format name@example.com"
                                                        )
                                                    );
                                                }

                                                if (
                                                    inputText.target.value
                                                        .length === 0
                                                ) {
                                                    setMessageError(
                                                        t(
                                                            "This field is required"
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <StyledLabel htmlFor="password">
                                            {t("Password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={inputPwd}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        messagePwdError,
                                                },
                                            }}
                                            onChange={(e: any) => {
                                                if (
                                                    generalMessageError !== ""
                                                ) {
                                                    setGeneralMessageError("");
                                                    setIsEnterInput(true);
                                                }
                                                setInputPwd(e.target.value);
                                            }}
                                            onBlur={(inputText: any) => {
                                                if (
                                                    inputText.target.value
                                                        .length === 0
                                                ) {
                                                    setMessageError(
                                                        t(
                                                            "This field is required"
                                                        )
                                                    );
                                                }
                                            }}
                                            placeholder={t("Password")}
                                            className="form-control__clz"
                                        />
                                    </div>
                                    {isEnterInput ? null : (
                                        <div
                                            className="d-flex justify-content-end"
                                            style={{
                                                marginBottom: "3%",
                                            }}
                                        >
                                            <label
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#dc3545",
                                                }}
                                            >
                                                {generalMessageError}
                                            </label>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-end forgot-password-button__clz">
                                        <div className="d-none custom-control custom-checkbox mb-4">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customControlInline"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customControlInline"
                                            >
                                                {t("Remember me")}
                                            </label>
                                        </div>
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            className="mb-5"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                                position: "fixed !important",
                                            }}
                                            onClick={() => {
                                                setIsForgotPasswordClick(true);
                                                setIsUnverifiedCode(false);
                                                setIsInvalidCode(false);
                                                toggleOpeningForgotPasswordModal();
                                            }}
                                        >
                                            {t("Forgot your password?")}
                                        </StyledButton>
                                    </div>

                                    <div className="d-flex justify-content-center submit-button__clz">
                                        <StyledButton
                                            variant="primary"
                                            rounded={true}
                                            outline={false}
                                            className="text-uppercase"
                                            type="submit"
                                        >
                                            {t("Login")}
                                        </StyledButton>
                                    </div>

                                    <div className="d-none">
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={
                                                handleCreateAccountButtonOnClickEvent
                                            }
                                        >
                                            {t("Create new account")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <ForgotPasswordModal
                isModalOpened={isModalOpened}
                toggleOpeningForgotPasswordModal={
                    toggleOpeningForgotPasswordModal
                }
                setIsForgotPasswordClick={setIsForgotPasswordClick}
                initEmail={inputEmail}
                setIsInvalidCode={setIsInvalidCode}
                isInvalidCode={isInvalidCode}
                isUnverifiedCode={isUnverifiedCode}
                setIsUnverifiedCode={setIsUnverifiedCode}
            />
            <BlocArchiveAccountLogin
                isModalOpened={isActiveUserModalOpned}
                setIsModalOpened={setIsActiveUserModalOpned}
                msgTitleModal={msgTitleModal}
            />
        </React.Fragment>
    );
}
